<template>
  <div class="flex min-h-full flex-1 flex-col-reverse sm:flex-col justify-center px-6 py-12 lg:px-8">
    <div class="sm:mx-auto sm:w-full sm:max-w-sm">
      <slot name="logo"/>
    </div>

    <div class="sm:mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
      <slot/>
    </div>
  </div>
</template>
